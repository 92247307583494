import { css } from '@emotion/react';

const frenchbeeFonts = css`
  @font-face {
    font-display: fallback;
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    src: url('/fonts/frenchbee/Roboto-Light.woff2');
  }

  @font-face {
    font-display: fallback;
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/frenchbee/Roboto-Regular.woff2');
  }

  @font-face {
    font-display: fallback;
    font-family: Roboto;
    font-style: normal;
    font-weight: 700;
    src: url('/fonts/frenchbee/Roboto-Bold.woff2');
  }

  @font-face {
    font-display: fallback;
    font-family: 'Trade Gothic LT Std';
    font-style: normal;
    font-weight: 700;
    src: url('/fonts/frenchbee/TradeGothicLTStd.woff2');
  }
`;

export default frenchbeeFonts;
